import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { translate, TranslocoService } from "@jsverse/transloco";
import { DialogService } from "primeng-v17/dynamicdialog";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { SafePipe } from "../../../shared/pipes/safe.pipe";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { DashboardBlockLayoutComponent } from "../dashboard-block-layout/dashboard-block-layout.component";
import { UpdateDialogComponent } from "../update-dialog/update-dialog.component";

@Component({
  selector: "app-ankaadia-updates",
  templateUrl: "./ankaadia-updates.component.html",
  imports: [
    TranslateDirective,
    DashboardBlockLayoutComponent,
    NgIf,
    NgFor,
    NgTemplateOutlet,
    AppDateTimePipe,
    SafePipe,
  ],
})
export class AnkaadiaUpdatesComponent implements OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  updates: any[];

  @Output() readonly popUpSeen = new EventEmitter<boolean>();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly dialogService: DialogService
  ) {}

  ngOnChanges(): void {
    const unseenVersions = this.updates?.filter((v) => !v.isSeen) || [];

    if (unseenVersions.length) {
      this.showUpdateDialog(unseenVersions);
    }
  }

  showUpdateDialog(updates?: any[]): void {
    const data = updates ?? this.updates;
    this.dialogService
      .open(UpdateDialogComponent, {
        data,
        header: translate("dashboard.ankaadiaUpdates"),
      })
      .onClose.subscribe((_) => this.popUpSeen.emit(true));
  }
}
