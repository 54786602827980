import { NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { DynamicDialogConfig } from "primeng-v17/dynamicdialog";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { SafePipe } from "../../../shared/pipes/safe.pipe";

@Component({
  selector: "app-update-dialog",
  templateUrl: "./update-dialog.component.html",
  imports: [NgFor, AppDateTimePipe, SafePipe],
})
export class UpdateDialogComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();

  updates: { date: Date; version: string; changes: string }[];

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.updates = this.config.data;
  }
}
