import { Component, Input } from "@angular/core";
import type { ChartData, ChartOptions, ChartType } from "chart.js";
import { ChartModule } from "primeng-v17/chart";
import { DashboardBlockLayoutComponent } from "../dashboard-block-layout/dashboard-block-layout.component";

@Component({
  selector: "app-dashboard-chart",
  templateUrl: "./dashboard-chart.component.html",
  imports: [DashboardBlockLayoutComponent, ChartModule],
})
export class DashboardChartComponent {
  @Input()
  label: string;

  @Input()
  labelIcon: string;

  @Input()
  type: ChartType;

  @Input()
  data: ChartData;

  @Input()
  options: ChartOptions;
}
