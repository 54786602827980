import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { DashboardBlockLayoutComponent } from "../dashboard-block-layout/dashboard-block-layout.component";

@Component({
  selector: "app-dashboard-list",
  templateUrl: "./dashboard-list.component.html",
  imports: [DashboardBlockLayoutComponent, NgIf, NgFor, NgTemplateOutlet],
})
export class DashboardListComponent<T> {
  @Input()
  label: string;

  @Input()
  labelIcon: string;

  @Input()
  items: T[];

  @ContentChild("item", { read: TemplateRef })
  itemTemplate: TemplateRef<any>;

  @ContentChild("empty", { read: TemplateRef })
  emptyTemplate: TemplateRef<any>;
}
