import { AsyncPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import {
  ActivityType,
  DashboardAnkaadiaUpdates,
  DashboardBirthdays,
  DashboardCount,
  DashboardCountPerDay,
  MetricType,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { ImagePreloadDirective } from "../../shared/directives/image-preload.directive";
import { ThumbnailPipe } from "../../shared/pipes/thumbnail.pipe";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { AnkaadiaUpdatesComponent } from "./ankaadia-updates/ankaadia-updates.component";
import { DashboardChartComponent } from "./dashboard-chart/dashboard-chart.component";
import { DashboardCounterComponent } from "./dashboard-counter/dashboard-counter.component";
import { DashboardListComponent } from "./dashboard-list/dashboard-list.component";
import { DashboardService } from "./dashboard.service";

interface DashboardMetrics {
  tasksFinished: DashboardCount;
  documentsUploaded: DashboardCount;
  candidatesCreated: DashboardCount;
  documentsExpiring: DashboardCount;
  candidatesLoggedIn: DashboardCountPerDay;
  candidateBirthdays: DashboardBirthdays;
  ankaadiaUpdates: DashboardAnkaadiaUpdates;
}

const metrics: DashboardMetrics = {
  tasksFinished: null,
  documentsUploaded: null,
  candidatesCreated: null,
  documentsExpiring: null,
  candidatesLoggedIn: null,
  candidateBirthdays: null,
  ankaadiaUpdates: null,
};

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
  imports: [
    TranslateDirective,
    DashboardCounterComponent,
    RouterLink,
    DashboardListComponent,
    ImagePreloadDirective,
    DashboardChartComponent,
    AnkaadiaUpdatesComponent,
    AsyncPipe,
    ThumbnailPipe,
  ],
})
export class DashboardComponent implements OnInit {
  protected readonly ActivityType = ActivityType;
  protected readonly language = this.transloco.getActiveLang();
  protected readonly dayNames = this.getDayNames();
  protected readonly metrics = metrics;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.update();
  }

  private update(): void {
    this.dashboardService.getMetrics().subscribe((metrics) => {
      this.metrics.tasksFinished = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.TasksFinished
      );
      this.metrics.documentsUploaded = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.DocumentsUploaded
      );
      this.metrics.candidatesCreated = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.CandidatesCreated
      );
      this.metrics.documentsExpiring = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.DocumentsExpiring
      );
      this.metrics.candidatesLoggedIn = metrics.find(
        (metric): metric is DashboardCountPerDay => metric.metricType === MetricType.CandidatesLoggedIn
      );
      this.metrics.candidateBirthdays = metrics.find(
        (metric): metric is DashboardBirthdays => metric.metricType === MetricType.CandidateBirthdays
      );
      this.metrics.ankaadiaUpdates = metrics.find(
        (metric): metric is DashboardAnkaadiaUpdates => metric.metricType === MetricType.AnkaadiaUpdates
      );
    });
  }

  private getDayNames(): string[] {
    const dayNames: string[] = translate("primeng.dayNamesMin");
    const dayOfWeek = new Date().getDay() + 1;
    return [...dayNames.slice(dayOfWeek, dayNames.length), ...dayNames.slice(0, dayOfWeek)];
  }

  lastDays(n: number): [Date, Date] {
    return [new Date(Date.now() - n * 24 * 60 * 60 * 1000), new Date()];
  }

  ankaadiaUpdatesSeen(updateSeenEvent: boolean): void {
    if (!updateSeenEvent && this.metrics?.ankaadiaUpdates?.releases) return;
    this.metrics.ankaadiaUpdates = {
      ...this.metrics.ankaadiaUpdates,
      releases: this.metrics.ankaadiaUpdates.releases.map((update) => ({ ...update, isSeen: true })),
    };
  }
}
